/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

*,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --bg3: #1f3961;
  --bg3-contrast: white;
  --bg3-text: white;
  --bg3-text-contrast: #828282;
  --bg: white;
  --bg-hover: #ffdd74;
  --bg2: #f8a102;
  --bg-text: #11171c;
  --bg-black: 0;
  --bg-black-text: white;
  --bg-shadow: rgb(0 0 0 / 68%);
  --link: #f44336;
  --border: #323232;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/Roboto-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/Roboto-Black.ttf');
  font-display: swap;
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  width: 100%;
}

h1::ng-deep,
h2::ng-deep,
h3::ng-deep,
h4::ng-deep {
  margin: 0;
}

.mat-typography h1 {
  margin: 0;
}

.mat-typography h2 {
  margin: 0;
}

.container {
  display: block;
  padding: 0 40px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

li {
}

h2.title {
  font-size: 20px !important;
  text-transform: uppercase;
  width: max-content;
  margin: 0 auto 40px !important;
  display: block;
  font-weight: 900;

  color: var(--bg3);
}

h2.title2 {
  color: 3px solid var(--bg3);
}

h2.title3 {
  color: var(--bg3-text);
}

a {
  text-decoration: none;
}

.banner-shadow {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: var(--bg-shadow);
}

:host::ng-deep {
  .mat-mdc-text-field-wrapper {
    width: 100%;
    border-radius: 10px;
  }
}

.mat-mdc-text-field-wrapper {
  border-radius: 10px !important;
}

.page-container {
  padding: 60px;
  display: block;
}

.snack-bar-panel.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--bg);
  .mdc-snackbar__label {
    color: var(--bg-text);
  }
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: white;
  }
}
.mat-mdc-unelevated-button.big-button {
  min-width: 200px;
  padding: 17px 0;
  height: auto;
}

@media (max-width: 780px) {
  .container {
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .page-container {
    padding: 40px 20px;
  }
  h2.title {
    display: table;
    width: auto;
    text-align: center;
    line-height: 1.2;
  }
}
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
